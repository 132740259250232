import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?lang=cz&paged=18
// http://www.dktech.cz/?p=55

const PostPriprava: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_podzimni_lov_mysi"
    >
      <PostHeader
        title="Příprava webu, fóra a změn serveru"
        link="/post_priprava"
        author="Algy"
        date="12.11.2013"
        time="20:23"
        category="Oznámení"
      />
      <p>Vítejte na stránkách nejstaršího serveru v Čechách. DKTech byl založen v roce 2004 a od té doby je součástí free L2 scény. Někdy se serveru dařilo lépe, jindy hůř. Množí hráči server DKTech pamatují pod jiným jménem a to GPL2. GamePark s námi spolupráci ukončil a vedení serveru se rozhodlo vrátit se k původnímu názvu.</p>
      <p>Veškeré nové informace budou sdělovány pouze na těchto stránkách, které budou postupně doplňovány. Prozatím prosím buďte shovívaví, na webu i fóru se stále pracuje.</p>
      <p>Nyní se domlouvá hosting a první zpráva se bude pravděpodobně &nbsp;týkat tohoto tématu.</p>
      <p style={{ textAlign: 'left' }}>S pozdravem<br />
        vedení serveru DKTech.cz</p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostPriprava

export { Head } from '../components/defaultHtmlHead'